<template>
  <div class="main">
    <div class="title">{{ pageTitle }}</div>
    <!-- 整体列表 -->
    <div class="listbox" v-if="!showChild">
      <div class="tip">账户列表</div>
      <!-- 启用禁用 -->
      <div class="flex">
        <el-button type="primary" @click="newAddClick()">新建账户</el-button>
        <div class="flex-right">
          <el-radio-group
            v-model="state"
            style="color: rgba(0, 0, 0, 0.65); margin-right: 35px"
            @change="stateChangeSearch()"
          >
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
          <el-button type="primary" plain size="mini" @click="reset()"
            >重置</el-button
          >
        </div>
      </div>
      <!-- table表格 -->
      <div class="table">
        <el-table
          :data="tableList"
          border
          :header-cell-style="{ color: 'rgba(0, 0, 0, 0.85)' }"
          style="width: 100%; margin-top: 20px; color: rgba(0, 0, 0, 0.85)"
        >
          <el-table-column type="selection" align="center" width="55">
          </el-table-column>
          <el-table-column label="序号" width="55" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            prop="username"
            width="80"
            label="账号"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="phoneNum"
            label="手机号"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <!-- <el-table-column prop="username" width="80" label="姓名" align='center'>
					</el-table-column> -->
          <el-table-column prop="roleName" label="角色名称" align="center">
          </el-table-column>
          <el-table-column label="状态" width="90" align="center">
            <template slot-scope="scope">
              <span
                :style="{
                  background: scope.row.state == 0 ? '#52C41A' : '#909399',
                }"
                class="jobCircle"
              ></span>
              <span>{{ scope.row.state == 0 ? "启用" : "禁用" }}</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button @click="editClick(scope.row)" 
              type="text" size="small" 
                >编辑</el-button
              >
              <!-- :disabled="scope.row.id==1" -->
              <!-- <el-button
                type="text"
                size="small"
                @click="delClick(scope.row.id)"
                >删除</el-button
              > -->
              <el-button
                type="text"
                size="small"
                @click="changeStateBan(scope.row)"
                >禁用</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="changeStateActive(scope.row)"
                >启用</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 5]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
          style="
            background-color: #fff;
            text-align: right;
            padding: 0px !important;
          "
        >
        </el-pagination>
      </div>
      <!-- 删除弹框 -->
      <!-- <div>
        <el-dialog title="提示" :visible.sync="dialogShow">
          <div class="dialogContent">
            <div class="icon">
              <i class="el-icon-info"></i>
            </div>
            <div class="font">
              <p class="big">是否删除账号？</p>
              <p class="small">删除后不可恢复</p>
            </div>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="dialogCancel()">取 消</el-button>
            <el-button size="small" type="primary" @click="dialogCancel()"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div> -->
    </div>
    <!-- 子组件 -->
    <div v-else>
      <Child
        :tabpane="selectNum"
        :record="record"
        :formDatas="formDatas"
      ></Child>
    </div>
  </div>
</template>

<script>
import Child from "./CreateAccount.vue";
export default {
  inject: ["reload"],
  data() {
    return {
      // 页面标题
      pageTitle: "系统设置 / 账户管理",
      // 员工状态
      state: null,
      currentPage: 1,
      pageSize: 10,
      totalPage: 10,
      tableList: [],
      // 分页
      currentPage4: 1,
      // 删除弹框是否显示
      dialogShow: false,
      // 子组件是否显示
      showChild: false,
      // 账户新增or编辑
      selectNum: "",
      // 传递子组件的值
      record: "",
      formDatas: {},
    };
  },

  mounted() {
    // 初始化列表
    this.getUsersList();
  },
  components: {
    Child,
  },
  methods: {
   
    // 获取用户列表
    getUsersList() {
      let roleId = localStorage.getItem("roleId");
      let param = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        state: this.state,
      };
      let param1 = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        state: this.state,
        projectId: localStorage.getItem("projectID"),
      };
      this.$post("/user/findUserList", (roleId === '1' ? param : param1))
        .then((res) => {
          if (res.code == 1) {
            this.tableList = res.data.list;
            this.totalPage = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    stateChangeSearch() {
      let roleId = localStorage.getItem("roleId");
      let param = {
        pageNum: 1,
        pageSize: this.pageSize,
        state: this.state,
      };
      let param1 = {
        pageNum: 1,
        pageSize: this.pageSize,
        state: this.state,
        projectId: localStorage.getItem("projectID"),
      };
      this.$post("/user/findUserList", (roleId === '1' ? param : param1))
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            this.tableList = res.data.list;
            this.totalPage = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    reset() {
      this.currentPage = 1;
      this.state = null;
      this.getUsersList();
    },
    // 新建账户
    newAddClick() {
      //重置表单
      this.formDatas = {};
      this.showChild = true;
      this.selectNum = "first";
      this.pageTitle = "系统设置 / 账户管理 / 新建账户";
      localStorage.setItem("tab", this.selectNum);
    },
    // 编辑账户
    editClick(row) {
      this.$nextTick(() => {
        //赋值
        this.formDatas = Object.assign({}, row);
      });
      console.log(this.formDatas)
      this.record = row.id;
      this.showChild = true;
      this.selectNum = "second";
      this.pageTitle = "系统设置 / 账户管理 / 编辑账户";
      localStorage.setItem("tab", this.selectNum);
    },
    // 删除账号
    // delClick(i) {
    // 	console.log(i);
    // 	this.dialogShow = true;
    // },
    // 启用
    changeStateActive(row) {
      let param = {
        id: row.id,
        state: 0,
      };
      this.$post("/user/updateState", param)
        .then((res) => {
          this.reload();
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 禁用
    changeStateBan(row) {
      let param = {
        id: row.id,
        state: 1,
      };
      this.$post("/user/updateState", param)
        .then((res) => {
          this.reload();
        })
        .catch(() => {
          console.log("异常");
        });
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getUsersList(this.state);
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getUsersList(this.state);
    },
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.main {
  margin: 10px;

  .title {
    color: rgba(0, 0, 0, 0.85);
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: undefined;
    line-height: 16px;
    margin: 15px;
  }

  .listbox {
    background: #fff;
    padding: 21px 24px;
    box-sizing: border-box;
    margin: 0 16px;

    // 新建账户btn
    .tip {
      margin: 0 0 16px;
    }

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin: 20px 0 0 0;
    }
  }

  // 表格
  .table {
    // 状态圆圈
    .jobCircle {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #67c23a;
      margin-right: 5px;
    }
  }

  // 分页
  .page {
    width: 100%;
    text-align: right;
    margin-top: 20px;
  }

  // dialog
  .dialogContent {
    display: flex;
    margin: 0 auto;
    width: 100%;

    .icon {
      width: 40%;
      text-align: right;
    }

    .el-icon-info {
      color: red;
      font-size: 40px;
      margin: 7px;
    }

    .font {
      width: 50%;
    }

    .big {
      font-size: 20px;
      margin-bottom: 5px;
    }
  }
}
</style>
