<template>
  <div class="main">
    <el-button
      type="primary"
      icon="el-icon-arrow-left"
      @click="backClick()"
      size="small"
      >返回</el-button
    >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="新建账户" name="first">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          size="small"
          class="demo-ruleForm"
        >
          <p class="tip">基本信息</p>
          <el-form-item label="账号：" label-width="100px" prop="username">
            <el-input
              style="width: 250px"
              clearable
              placeholder="请设置您的账号"
              v-model="ruleForm.username"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="密码：" label-width="100px" prop="password">
            <el-input
              style="width: 250px"
              clearable
              show-password
              placeholder="请输入您的密码"
              v-model="ruleForm.password"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="手机号：" label-width="100px" prop="phoneNum">
            <el-input
              style="width: 250px"
              clearable
              placeholder="请输入您的手机号"
              v-model="ruleForm.phoneNum"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色名称：" label-width="100px" prop="rolename">
            <el-select
              v-model="ruleForm.roleId"
              placeholder="请选择您的角色名称"
              style="width: 250px"
            >
              <el-option
                style="width: 250px"
                v-for="item in role"
                :key="item.key"
                :label="item.roleName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="状态：" label-width="100px" prop="state">
            <el-radio-group v-model="ruleForm.state">
              <el-radio
                v-for="item in state"
                :key="item.key"
                :label="item.label"
                :value="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item> -->
          <!-- <el-form-item label="姓名：" label-width="100px" prop="name">
            <el-input
              style="width: 250px"
              clearable
              placeholder="请输入您的姓名"
              v-model="ruleForm.name"
            ></el-input>
          </el-form-item> -->
          <el-form-item class="handleFrom">
            <el-button @click="resetForm('ruleForm')">取消</el-button>
            <el-button type="primary" @click="submitAddForm()">确认</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="编辑账户" name="second">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          size="small"
          class="demo-ruleForm"
        >
          <p class="tip">基本信息</p>
          <el-form-item label="账号：" label-width="100px" prop="username">
            <el-input
              style="width: 250px"
              clearable
              placeholder="请设置您的账号"
              v-model="ruleForm.username"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="密码：" label-width="100px" prop="password">
            <el-input
              style="width: 250px"
              clearable
              show-password
              placeholder="请输入您的密码"
              v-model="ruleForm.password"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="手机号：" label-width="100px" prop="phoneNum">
            <el-input
              style="width: 250px"
              clearable
              placeholder="请输入您的手机号"
              v-model="ruleForm.phoneNum"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色名称：" label-width="100px" prop="roleId">
            <el-select
              v-model="ruleForm.roleId"
              placeholder="请选择您的角色名称"
              style="width: 250px"
            >
              <el-option
                style="width: 250px"
                v-for="item in role"
                :key="item.key"
                :label="item.roleName"
                :value="item.id"
              ></el-option
            ></el-select>
          </el-form-item>

          <el-form-item class="handleFrom">
            <el-button @click="resetForm('ruleForm')">取消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >确认</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Child",
  inject: ["reload"],
  data() {
    return {
      activeName: "first",
      ruleForm: {
        username: "",
        // password: "",
        name: "",
        phoneNum: "",
        state: null,
        // 角色名称
        roleName: "",
        roleId: "",
        // 角色描述
        roledesc: "",
        // 功能权限
        authority: [],
      },
      role: {},
      // 状态管理
      //   state: [
      //     {
      //       label: "启用",
      //       value: 0,
      //     },
      //     {
      //       label: "禁用",
      //       value: 1,
      //     },
      //   ],
      rules: {
        name: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
        // password: [
        //   { required: true, message: "请输入您的密码", trigger: "blur" },
        //   { validator: validatePsw, trigger: "change" },
        //   { min: 6, message: "密码不能少于6位", trigger: "blur" },
        // ],
        username: [
          { required: true, message: "请输入您的用户名", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
        phoneNum: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
          {
            pattern: /^(1[3|4|5|6|7|8|9])\d{9}$|^0\d{2,3}-\d{7,8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["tabpane", "record", "formDatas"],
  mounted() {
    this.activeName = this.tabpane;
    this.ruleForm = this.formDatas;
    console.log(localStorage.getItem("roleId"))
    // 父组件传值为超级管理员、管理员角色名称的显示
    if (localStorage.getItem("roleId") == 1) {
      if (this.ruleForm.roleId == 1) {
        this.ruleForm.roleId = "超级管理员";
      }
    } else if (localStorage.getItem("roleId") == 2) {
      if (this.ruleForm.roleId == 1) {
        this.ruleForm.roleId = "超级管理员";
      } else if (this.ruleForm.roleId == 2) {
        this.ruleForm.roleId = "管理员";
      }
    }
  },
  created() {
    // 加载角色列表
    this.getRoleData();
  },
  methods: {
    // 获取角色列表
    getRoleData() {
      let param = {
        pageNum: "1",
        pageSize: "10",
      };
      this.$post("/user/role/findRoleList", param)
        .then((res) => {
          if (res.code == 1) {
            if (localStorage.getItem("roleId") == 1) {
              this.role = res.data.list;
              this.role.splice(0, 1);
            } else {
              this.role = res.data.list;
              this.role.splice(0, 1);
              this.role.splice(0, 1);
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 切换新建和编辑账户
    handleClick(tab) {
      this.activeName = tab.name;
      if (tab.name == "first") {
        this.$refs["ruleForm"].resetFields();
        this.ruleForm = {};
      } else if (tab.name == "second") {
        if (this.record == "" || this.record == undefined) {
          console.log("无值传入");
        } else {
          console.log(this.record);
        }
      }
    },
    // 返回账户列表页面
    backClick() {
      this.$parent.showChild = false;
    },
    // add确认提交
    submitAddForm() {
      // @ts-ignore
      this.$refs.ruleForm.validate(async (valid) => {
        console.log(valid);
        if (valid) {
          //   alert("submit!");
          const projectId = localStorage.getItem("projectID");
          let param = { ...this.ruleForm, projectId };
          this.$post("/user/addOrUpdate", param)
            .then((res) => {
              if (res.code === "1") {
                this.$message({
                  message: "添加用户成功!",
                  type: "success",
                  duration: 1000,
                });
                // 刷新整个页面
                this.reload();
                // console.log(res.mes);
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                  duration: 1000,
                });
              }
            })
            .catch((error) => {
              this.$message({
                message: error.msg,
                type: "error",
                duration: 1000,
              });
            });
          // this.$refs.ruleForm.resetFields();
          // this.$parent.showChild = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // edit确认提交
    submitForm() {
      // @ts-ignore
      this.$refs.ruleForm.validate(async (valid) => {
        console.log(valid);
        if (this.ruleForm.roleId == "超级管理员") {
          this.ruleForm.roleId = 1;
        } else if (this.ruleForm.roleId == "管理员") {
          this.ruleForm.roleId = 2;
        }
        if (valid) {
          let param = {
            username: this.ruleForm.username,
            phoneNum: this.ruleForm.phoneNum,
            roleId: this.ruleForm.roleId,
            id: this.record,
          };
          console.log(param);
          this.$post("/user/addOrUpdate", param)
            .then((res) => {
              if (res.code === "1") {
                this.$message({
                  message: "用户修改成功!",
                  type: "success",
                  duration: 1000,
                });
                // 刷新整个页面
                this.reload();
                this.$refs["ruleForm"].resetFields();
                this.$parent.showChild = false;
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                  duration: 1000,
                });
              }
            })
            .catch((error) => {
              this.$message({
                message: error.msg,
                type: "error",
                duration: 1000,
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$parent.showChild = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  margin: 10px;
  color: #333;
  background: #fff;
  padding: 21px 24px;
  box-sizing: border-box;

  .tip {
    margin: 0 0 10px;
    color: rgba(0, 0, 0, 0.85);
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: blod;
    line-height: 24px;
  }

  // 角色信息
  .roleInfo {
    display: flex;
  }

  // 按钮处理模块
  .handleFrom {
    margin: 20px 20px 40px;
  }
}
</style>
